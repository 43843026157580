<template>
  <v-card class="mx-auto" v-if="rewards.length > 0">
    <v-carousel :continuous="true" hide-delimiter-background hide-delimiters height="380px">
      <v-carousel-item
        v-for="(item, i) in rewards"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-card-title
          >{{ $t("app.rewards") }} <v-spacer></v-spacer>
          <v-btn outlined small :to="{ name: 'list-rewards' }">{{
            $t("app.checkAll")
          }}</v-btn></v-card-title
        >
        <reward-card :item="item" :limit-text="limitText" :icon="icon" :namespace="namespace" />
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "RewardsCarousel",
  computed: {
    ...mapGetters({
      loading: "options/loading"
    }),
    rewards() {
      return this.$store.getters["rewards/items"];
    }
  },
  components: { RewardCard: () => import("@/components/core/cards/RewardCard") },
  mounted() {
    this.getData();
  },
  data() {
    return {
      limitText: "90",
      icon: "mdi-cellphone-wireless",
      namespace: "rewards"
    };
  },
  methods: {
    async getData() {
      await this.$store.dispatch("rewards/getItems");
    }
  }
};
</script>
